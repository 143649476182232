import React, {useEffect, useState} from "react";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import FormAlert from "./../components/FormAlert";
import {requireAuth, useAuth} from "../util/auth";
import {useRouter} from "../util/router";
import {useTranslation} from "react-i18next";


function PurchasePage(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();


  const plans = [
    {
      id: "starter",
      name: t("pricing.plans.starter.name"),
      price: "139",
      deal: "99",
      subtitle: t("pricing.plans.starter.subtitle"),
      perks: t("pricing.plans.starter.perks", {returnObjects: true}),
      // link: "https://buy.stripe.com/00geWr4EZ0Fd3KMcMQ" // $49
      link: "https://buy.stripe.com/eVa5lR1sN5Zx956eV1" // $99
    },
    {
      id: "business",
      name: t("pricing.plans.business.name"),
      price: "399",
      deal: "249",
      featured: true,
      subtitle: t("pricing.plans.business.subtitle"),
      perks: t("pricing.plans.business.perks", {returnObjects: true}),
      link: "https://buy.stripe.com/cN2dSngnH5Zx1CE28e"
    },
    {
      id: "enterprise",
      name: t("pricing.plans.pro.name"),
      price: "799",
      deal: "599",
      subtitle: t("pricing.plans.pro.subtitle"),
      perks: t("pricing.plans.pro.perks", {returnObjects: true}),
      // link: "https://buy.stripe.com/00gg0vdbvcnV6WY6ot" // $99
      link: "https://buy.stripe.com/00g29F7RbcnVchi3cl" // $599
    },
  ];


  // Function to get a link and redirect to there
  const getLink = (link) => {
    window.location.href = link;
  }


  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user already has an active plan
      // then take them to Stripe billing
      router.push("/settings/billing");
    } else {
      // Otherwise go to checkout
      // redirectToCheckout(router.query.plan).catch((error) => {
      //   setFormAlert({
      //     type: "error",
      //     message: error.message,
      //   });
      // });
      //   get planid from url query
      const planId = router.query.plan;
      console.log("planId: " + planId);
      // if planid is valid, redirect to checkout
      const selectedPlan = plans.find((plan) => plan.id === planId);
      if (selectedPlan) {
        getLink(selectedPlan.link);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Purchase"/>
      <PageLoader>
        {formAlert && (
          <div className="mb-4 mx-auto max-w-md">
            <FormAlert type={formAlert.type} message={formAlert.message}/>
          </div>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);
