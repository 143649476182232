import React, {useState} from "react";
import {BookmarkIcon, CheckCircleIcon, GiftIcon, SparklesIcon, FireIcon} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import {useAuth} from "./../util/auth";
import {useTranslation} from "react-i18next";
import FaqSection from "./FaqSection";

function PricingSection(props) {
  const {t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language on button click
  };
  const auth = useAuth();

  const [basicModalOpen, setBasicModalOpen] = useState(false);

  const plans = [
    {
      id: "starter",
      name: t("pricing.plans.starter.name"),
      price: "139",
      deal: "99",
      savings: "29%",
      subtitle: t("pricing.plans.starter.subtitle"),
      perks: t("pricing.plans.starter.perks", {returnObjects: true}),
      // link: "https://buy.stripe.com/00geWr4EZ0Fd3KMcMQ" // $49
      link: "https://buy.stripe.com/eVa5lR1sN5Zx956eV1" // $99
    },
    {
      id: "business",
      name: t("pricing.plans.business.name"),
      price: "399",
      deal: "249",
      savings: "38%",
      featured: true,
      subtitle: t("pricing.plans.business.subtitle"),
      perks: t("pricing.plans.business.perks", {returnObjects: true}),
      link: "https://buy.stripe.com/cN2dSngnH5Zx1CE28e"
    },
    {
      id: "enterprise",
      name: t("pricing.plans.enterprise.name"),
      price: "799",
      deal: "599",
      savings: "25%",
      subtitle: t("pricing.plans.enterprise.subtitle"),
      perks: t("pricing.plans.enterprise.perks", {returnObjects: true}),
      // link: "https://buy.stripe.com/00gg0vdbvcnV6WY6ot" // $99
      link: "https://buy.stripe.com/00g29F7RbcnVchi3cl" // $599
    },
  ];


  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        
        {/* Promotional Banner */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 dark:bg-white rounded-lg p-6 text-white dark:text-gray-900 shadow-lg max-w-xl mx-auto">
          <div className="flex items-center justify-center space-x-2 mb-2">
            <GiftIcon className="w-6 h-6 text-yellow-300" />
            <h3 className="text-xl font-bold">{t("pricing.promo.title")}</h3>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 md:space-x-6 text-center">
            <div className="bg-white bg-opacity-20 rounded-full px-4 py-2">
              <p className="font-semibold">{t("pricing.promo.onboarding.title")}</p>
              <p className="text-sm text-blue-100">{t("pricing.promo.onboarding.subtitle")}</p>
            </div>
            <div className="bg-white bg-opacity-20 rounded-full px-4 py-2">
              <p className="font-semibold">{t("pricing.promo.video_posts.title")}</p>
              <p className="text-sm text-blue-100">{t("pricing.promo.video_posts.subtitle")}</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 lg:py-6">
          {plans.map((plan, index) => (
            <div
              className={
                "rounded-lg shadow-sm flex flex-col border-2" +
                (plan.featured
                  ? " bg-blue-50 lg:border-4 border-blue-300 hover:border-blue-400 relative lg:-mx-2 lg:-my-6"
                  : "") +
                (!plan.featured
                  ? " bg-gray-100 border-gray-200 hover:border-gray-300"
                  : "")
              }
              key={index}
            >
              {plan.featured && (
                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <div className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-4 py-1 rounded-full text-sm font-semibold flex items-center space-x-1">
                    <SparklesIcon className="w-4 h-4" />
                    <span>{t("pricing.best_value")}</span>
                  </div>
                </div>
              )}

              <div className="p-5 lg:p-6 text-center bg-white rounded-t-lg">
                <span
                  className="inline-block text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-blue-200 bg-opacity-50 text-blue-600 rounded-full mb-4">
                  {plan.name}
                </span>
                <div className="mb-1">
                  <div className="flex flex-col items-center justify-center space-y-1 mb-2">
                    <div className="flex items-center space-x-2">
                      <span className="text-3xl lg:text-4xl font-extrabold text-gray-700">
                        ${plan.deal}
                      </span>
                      <span className="text-gray-700 font-semibold">/{t("pricing.month")}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-lg text-gray-400 line-through">
                        ${plan.price}/{t("pricing.month")}
                      </span>
                      <div className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm font-semibold">
                        {t("pricing.save")} {plan.savings}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600 text-sm font-medium">
                    {plan.subtitle}
                  </p>
                </div>
              </div>

              {plan.perks && (
                <div
                  className={
                    "p-5 lg:p-6 space-y-5 lg:space-y-6 text-gray-700 grow" +
                    (plan.featured ? " text-blue-900" : "")
                  }
                >
                  <ul className="space-y-4 text-sm lg:text-base">
                    {plan.perks.map((perk, index) => (
                      <li className="flex items-center space-x-2" key={index}>
                        <CheckCircleIcon className="text-emerald-500 inline-block w-5 h-5"/>
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="px-5 pb-5 lg:px-6 lg:pb-6">
                <Button
                  component="a"
                  href={
                    auth.user
                      ? `${plan.link}`
                      : `/auth/signup?next=/purchase/${plan.id}`
                  }
                  target={auth.user ? "_blank" : "_self"}
                  size="lg"
                  variant={plan.featured ? "primary" : "dark"}
                  isBlock={true}
                >
                  {t("pricing.select_plan")}
                </Button>

              </div>
            </div>
          ))}
        </div>

        <FaqSection
          title={t("faq.title")}
          subtitle=""
          strapline=""
          size="md"
          bgColor=""
          bgImage=""
          bgImageOpacity={1}
          textColor=""
          showSupportButton={false}
          supportUrl="https://zendesk.com"
        />


      </div>
    </Section>


  );


}

export default PricingSection;
