import React from "react";
import {ChatBubbleLeftEllipsisIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Chat, { openChat } from "./Chat";

function ChatButton(props) {
  return (
    <>
      <Chat />
      <div className="mt-0">
        <p className="text-base">
          <div className="mt-0">
            <button
              onClick={(e) => {
                e.preventDefault();
                window.$crisp.push(["do", "chat:open"]);
              }}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <ChatBubbleLeftEllipsisIcon className="w-5 h-5 mr-2"/> Chat with us
            </button>
          </div>
        </p>
      </div>
    </>
  );
}

export default ChatButton;